<template>
  <div id="about-page">
    <div class="banner banner-dark smaller-title no-bg">
      <div class="wrapper">
        <h1>
          {{ $t("header.AboutUs") }}
        </h1>
        <div class="txt">
          <p style="font-size: 18px">{{ $t("aboutUs.Subtitle") }}</p>
        </div>
        <!-- end of txt -->
      </div>
      <!-- end of wrapper -->
    </div>
    <!-- end of banner -->

    <div class="inner-about">
      <div class="mozaik">
        <div class="wrapper">
          <div class="pusher">
            <div class="rowflex col-2">
              <div class="block">
                <div class="item" style="height: 126px">
                  <span
                    class="cvr-bg"
                    style="background-image: url('/img/dummy/about/1.jpg');"
                  ></span>
                  <h3>{{ menu1 }}</h3>
                  <div class="action">
                    <router-link to="/about-us/who-are-we" class="button">
                      {{ $t("aboutUs.SeeDetails") }}
                    </router-link>
                  </div>
                  <!-- end of action -->
                </div>
                <!-- end of item -->
                <div class="item" style="height: 126px">
                  <span
                    class="cvr-bg"
                    style="background-image: url('/img/dummy/about/2.jpg');"
                  ></span>
                  <h3>{{ menu3 }}</h3>
                  <div class="action">
                    <router-link
                      to="/about-us/who-should-use-rubii"
                      class="button"
                    >
                      {{ $t("aboutUs.SeeDetails") }}
                    </router-link>
                  </div>
                  <!-- end of action -->
                </div>
                <!-- end of item -->
              </div>
              <!-- end of block -->
              <div class="item" style="height: 268px">
                <span
                  class="cvr-bg"
                  style="background-image: url('/img/dummy/about/3.jpg');"
                ></span>
                <h3>{{ menu4 }}</h3>
                <div class="action">
                  <router-link to="/about-us/rent-to-own" class="button">
                    {{ $t("aboutUs.SeeDetails") }}
                  </router-link>
                </div>
                <!-- end of action -->
              </div>
              <!-- end of item -->
            </div>
            <!-- end of rowflex -->
            <div class="item" style="height: 80px">
              <span
                class="cvr-bg"
                style="background-image: url('/img/dummy/about/4.jpg');"
              ></span>
              <h3>{{ menu2 }}</h3>
              <div class="action">
                <router-link to="/about-us/why-rubii" class="button">
                  {{ $t("aboutUs.SeeDetails") }}
                </router-link>
              </div>
              <!-- end of action -->
            </div>
            <!-- end of item -->
            <div class="rowflex col-2-3">
              <div class="item" style="height: 126px">
                <span
                  class="cvr-bg"
                  style="background-image: url('/img/dummy/about/5.jpg');"
                ></span>
                <h3>{{ menu6 }}</h3>
                <div class="action">
                  <router-link to="/about-us/commissions" class="button">
                    {{ $t("aboutUs.SeeDetails") }}
                  </router-link>
                </div>
                <!-- end of action -->
              </div>
              <!-- end of item -->
              <div class="item" style="height: 126px">
                <span
                  class="cvr-bg"
                  style="background-image: url('/img/dummy/about/6.jpg');"
                ></span>
                <h3>{{ menu5 }}</h3>
                <div class="action">
                  <router-link
                    to="/about-us/non-aggressive-selling"
                    class="button"
                  >
                    {{ $t("aboutUs.SeeDetails") }}
                  </router-link>
                </div>
                <!-- end of action -->
              </div>
              <!-- end of item -->
            </div>
            <!-- end of rowflex -->
            <div class="rowflex col-3-2">
              <div class="item" style="height: 80px">
                <span
                  class="cvr-bg"
                  style="background-image: url('/img/dummy/about/7.jpg');"
                ></span>
                <h3>{{ menu7 }}</h3>
                <div class="action">
                  <router-link to="/about-us/visi-misi" class="button">
                    {{ $t("aboutUs.SeeDetails") }}
                  </router-link>
                </div>
                <!-- end of action -->
              </div>
              <!-- end of item -->
              <div class="item" style="height: 80px">
                <span
                  class="cvr-bg"
                  style="background-image: url('/img/dummy/about/8.jpg');"
                ></span>
                <h3>{{ menu8 }}</h3>
                <div class="action">
                  <router-link to="/about-us/article" class="button">
                    {{ $t("aboutUs.SeeDetails") }}
                  </router-link>
                </div>
                <!-- end of action -->
              </div>
              <!-- end of item -->
            </div>
            <!-- end of rowflex -->
          </div>
          <!-- end of pusher -->
        </div>
        <!-- end of wrapper -->
      </div>
      <!-- end of mozaik -->
    </div>
    <!-- end of inner about -->
  </div>
</template>

<script>
export default {
  name: "AboutUs",
  metaInfo: {
    title: "About Us"
  },
  data() {
    return {
      menu1: null,
      menu2: null,
      menu3: null,
      menu4: null,
      menu5: null,
      menu6: null,
      menu7: null,
      menu8: null
    };
  },
  mounted() {
    this.getContent();
  },
  methods: {
    getContent() {
      this.$axios
        .get(`/about-us`, {
          params: {
            lang: this.$store.state.locale.lang
          }
        })
        .then(response => {
          this.menu1 = response.data.data.rows.find(
            item => item.menu == 1
          ).menuName;
          this.menu2 = response.data.data.rows.find(
            item => item.menu == 2
          ).menuName;
          this.menu3 = response.data.data.rows.find(
            item => item.menu == 3
          ).menuName;
          this.menu4 = response.data.data.rows.find(
            item => item.menu == 4
          ).menuName;
          this.menu5 = response.data.data.rows.find(
            item => item.menu == 5
          ).menuName;
          this.menu6 = response.data.data.rows.find(
            item => item.menu == 6
          ).menuName;
          this.menu7 = response.data.data.rows.find(
            item => item.menu == 7
          ).menuName;
          this.menu8 = response.data.data.rows.find(
            item => item.menu == 8
          ).menuName;
        });
    }
  }
};
</script>
